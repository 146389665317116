<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" :class="text" app :color="drawerColor">
      
      <div class="d-flex align-center justify-center py-10">
        <v-img :src="logourl" max-width="100%"></v-img>
      </div>
      <v-list nav>
        <v-list-item>
          <h2>Welcome {{ this.data.firstname }}</h2>
        </v-list-item>
        <v-list-item>
          <h3 v-if="show == true">{{ this.data.clientname }}</h3>
        </v-list-item>
        <v-list-item 
          v-for="(route, index) in routes" 
          :to="route.path" 
          :prepend-icon="route.meta.icon" 
          :key="index">
          <v-list-item-title>{{route.name}}</v-list-item-title>
        </v-list-item>
        <v-list-item>
        </v-list-item>
        <v-list-item>
          <a :href="email" :class="text">{{ email.substring(7)}}</a>
        </v-list-item>
        <v-list-item>
          {{ phone }}
        </v-list-item>
        <v-list-item>
          8am-5pm EST Mon.-Fri.
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      app
      flat
      color="light"
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"/> 
      <v-btn @click="logoutReload" icon fab><v-icon>mdi-logout</v-icon></v-btn>
    </v-app-bar>
    <v-main>
      <snack-bar />
      <router-view/>
      <MessagesMenu/>
      <v-dialog width="unset" v-model="dialog">
        <UpdateLog :changes="changes" @close="dialog = !dialog"/>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<style>
.theme--light.v-application {
  background: #f5f5f5;
}
.theme--light.v-application .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.theme--light.v-application .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.theme--light.v-application .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    height: 80px;
}
.theme--light.v-application .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
    box-shadow: none;
    background: #f5f5f5;
}
</style>

<script>
import MessagesMenu from '../components/messages/MessagesMenu.vue'
import UpdateLog from '../components/UpdateLog.vue'
import {auth, api, messages} from '../sharedPlugin'
import {authorizedRoutes} from '../router'
import SnackBar from '../components/SnackBar.vue';
import Storage from '../utils/storage'
import {logoutHelper} from '../utils/logout';
import axios from 'axios'
import HavenLogo from '../assets/HavenCryo2.png'
import PreludeLogo from '../assets/prelude-logo-light.png'

export default {
  components: { MessagesMenu, SnackBar, UpdateLog },
  name: 'Home',
  data: () => ({
    dialog: false,
    changes: '',
    phone: '(615)550-7353',
    email: 'mailto:cryoservices@inceptionllc.com',
    data: [],
    homeurl: window.location.href,
    show: true,
    text: 'white--text',
    drawerColor: "primary",
    logourl: PreludeLogo,
    drawer: true,
    routes: authorizedRoutes,
    url: 'https://apiv1.inceptionllc.com',
  }),
  computed: {
    ...auth.mapComputed(['user'])
  },
  methods: {
    ...auth.mapMethods(['logout']),
    ...messages.mapMethods(['getConversationsAsync']),
    logoutReload() {
      logoutHelper(this.logout);
    },
    async getPatientInfo() {
      return await axios
        .post(`${this.url}/api/v1/inova/patients/getConnectPatient`, {
          connectId: this.user.userId
        }, {
          headers: {
            'x-api-key': '58b54801044df5a0536c25db11c653c743761b2db21e044aedb9f91caa6138a5'
          },
        })
        .then((response) => {
          this.data = response.data[0]
        })
        .catch((err) => {
          console.error(err)
        })
    },
    async checkVersions(){
      const currentVersion = await api.Versions.latestChanges()
      try{
        const storedVersionId = await Storage.get("versionId")
        if(storedVersionId != currentVersion.id){
          this.changes = currentVersion.notes
          this.dialog = true
          Storage.set("versionId", currentVersion.id)
        }
      } catch (error) {
        Storage.set("versionId", currentVersion.id)
        console.error(error)
      }
    }
  },
  beforeMount(){
    var url = window.location.href
    if(url.includes("havencryo")){
      this.logourl = HavenLogo
      this.drawerColor = "white"
      this.text = 'black--text'
      this.show = false;
      this.email = 'mailto:info@havencryo.com'
      this.phone = '(210)664-1988'
    }
    if(url.includes("localhost")){
      this.logourl = HavenLogo
      this.drawerColor = "white"
      this.show = false;
      this.text = 'black--text'
      this.email = 'mailto:info@havencryo.com'
      this.phone = '(210)664-1988'
    }
  },
  mounted(){
    this.getConversationsAsync();
    this.checkVersions();
    this.getPatientInfo();
    
  } 
}
</script>
