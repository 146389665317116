<template>
  <v-card rounded="0" flat width="100%" height="100%" class="pa-0">
    <v-toolbar dark flat color="primary" style="width: 100%;">
      <v-row align="center">
        <v-col class="text-left">
          <v-btn icon @click="handleBackButtonPress">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-col>
        <v-spacer/>
        <v-col>
          <v-toolbar-title  class="text-center">{{subject}}</v-toolbar-title>
        </v-col>
        <v-spacer/>
        <v-col/>
      </v-row>
    </v-toolbar>
    <v-card flat height="calc(100% - 140px)">
      <MessageList :cols="'auto'"/>
    </v-card>
    <v-card-actions v-if="!loading">
      <MessageTextInput style="width: 100%"/>
    </v-card-actions>
  </v-card>
</template>

<script>
import { messages } from '../../sharedPlugin'
import MessageList from './MessageList.vue';
import MessageTextInput from './MessageTextInput.vue';

export default {
  components: { MessageList, MessageTextInput },
  computed: {
    ...messages.mapComputed(['loading', 'currentConversation']),
    subject() {
      return this.currentConversation?.subject ?? ""
    }
  },
  methods: {
    ...messages.mapMethods(['deselectConversation']),
    handleBackButtonPress() {
      this.deselectConversation();
      this.$emit('back');
    }
  },
}
</script>

<style scoped>
.scrollCol{
  overflow-y: auto;
}
</style>