<template>
  <v-menu
    top
    left
    offset-y
    offset-x
    transition="slide-x-reverse-transition"
    v-model="isShown"
    :close-on-click="false"
  >
  
    <template v-slot:activator="{ on }">
      <v-btn
        fab
        v-on="on"
        color="primary"
        fixed
        right
        bottom
      >
        <v-badge
          :content="totalUnreadCount"
          :value="totalUnreadCount"
          overlap
          color="red"
        >
          <v-icon>
            mdi-message
          </v-icon>
        </v-badge>
      </v-btn>
    </template>
    <initialization-check>
      <v-sheet class="msg" @click.stop>
        <NewMessageForm 
          v-if="newMessageForm && !selectedConversation" 
          @newMessageForm="newMessageForm = !newMessageForm"
          @goToConversation="newMessageForm = false"
        />
        <Conversations 
          v-if="!newMessageForm && !selectedConversation" 
          styles="max-height: 88%"
          @newMessageForm="newMessageForm = true"
        />
        <Messages 
          v-if="!newMessageForm && selectedConversation"
        />
      </v-sheet>
    </initialization-check>
  </v-menu>
</template>

<script>
import {messages} from '../../sharedPlugin'
import Messages from './Messages.vue'
import NewMessageForm from './NewMessageForm.vue'
import Conversations from './Conversations.vue'
import InitializationCheck from './InitializationCheck.vue'

export default {
  components: {
    Messages, 
    NewMessageForm, 
    Conversations,
    InitializationCheck
  }, 
  data () {
    return {
      newMessageForm: false,
    }
  },
  computed: {
    ...messages.mapComputed(['shown', 'totalUnreadCount', 'selectedConversation']),
    isShown: {
      get () {
        return this.shown
      },
      set (val) {
        this.setShown(val)
      }
    },
  },
  methods: {
    ...messages.mapMethods(['setShown'])
  },
}
</script>

<style>
.v-menu__content{
  overflow: hidden;
}

.body {
  height: 100%
}

.msg {
  width: 500px;
  height: 500px;
}

.v-btn--fab.v-size--default.v-btn--absolute.v-btn--bottom {
  bottom: 16px
}

</style>